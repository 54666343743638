<template>
  <vx-card no-shadow>
    <b-container class="divide-x divide-green-500">
      <b-row class="p-3">
        <b-col style="margin-top: 25px" class="lg:mt-0 md:mt-0" md="6" sm="12">
          <h4>تنظیمات پیامک تایید</h4>
          <hr />
          <p class="mt-6 text-white">
            این امکان برای شما
            <span :class=" !!activeUser ? 'text-success' : 'text-warning'">{{
              !!activeUser ? "فعال" : "غیرفعال"
            }}</span>
            می باشد
          </p>
          <p class="text-white leading-loose">
            با فعال شدن بودن این امکان برای حذف یا ویرایش در ویرا ابر های خود کد
            تایید برای شما ارسال خواهد شد. و پس از تایید کد پیامکی ویرایش صورت
            خواهد پذیرفت.
          </p>
          <vs-button
            class="ml-auto w-50 secondary-color rounded"
            @click="setConfirmPopup(!!activeUser)"
          >
            {{ !!activeUser ? "غیرفعال کردن" : "فعال کردن" }}
          </vs-button>
        </b-col>


      </b-row>
      <div>
        <vs-popup :active.sync="requestPrompt" title="حذف  ابر">
          <div class="con-exemple-prompt">
            <p>
              کد تایید ارسالی به ایمیل یا (شماره همراه) خود را در فیلد زیر وارد نمایید.
            </p>
            <vs-input
              class="w-full mb-3"
              v-model="requestCode"
              label-placeholder="کد تایید"
            ></vs-input>
            <vs-button
              class="float-right rounded w-25"
              color="danger"
              @click="changeConfirmAction"
              >ثبت تغییرات</vs-button
            >
          </div>
        </vs-popup>
      </div>
    </b-container>
  </vx-card>
</template>

<script>
import { Validator } from 'vee-validate'
const dict = {
  custom: {
    oldpassword: {
      required: 'لطفا رمز عبور را وارد کنید'
    },
    newpassword: {
      required: 'لطفا رمز عبور را وارد کنید'
    },
    confirm_password: {
      confirmed: 'رمز عبور با تکرار رمز عبور یکسان نیست',
      required: 'لطفا رمز عبور خود تایید کنید'
    }
  }
}

// register custom messages
Validator.localize('en', dict)
export default {
  data () {
    return {
      status: '',
      requestPrompt: false,
      requestCode: '',
      smsChange: '',
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    }
  },
  computed: {
    activeUser () {
      return this.$store.state.AppActiveUser.sms_confirm_action
    },
    validateForm () {
      return (
        !this.errors.any() &&
        this.old_password !== '' &&
        this.new_password !== '' &&
        this.confirm_new_password !== '' &&
        this.confirm_password !== ''
      )
    }
  },
  methods: {
    setConfirmPopup (d) {
      this.status = !d ? 1 : 0
      if (this.activeUser === 1) {
        this.requestConfirmCode()
        this.requestPrompt = true
      } else {
        this.changeConfirmAction()
      }
    },
    requestConfirmCode () {
      this.$http
        .post('/user/confirm/action/request', {
          sms_confirm_action: this.status
        })
        .then((result) => {
          this.$vs.notify({ text: result.data.message })
          this.$store.state.AppActiveUser.sms_confirm_action = this.status

        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message })
        })
    },
    changeConfirmAction () {
      this.$http
        .post('user/confirm/action/change', {
          sms_confirm_action: this.status,
          confirm_code: this.requestCode
        })
        .then((result) => {
          const header = localStorage.getItem('accessToken')
          this.$store.dispatch('auth/fetchUserData', header)
          this.$vs.notify({ text: result.data.message, color: 'success' })
          this.$store.state.AppActiveUser.sms_confirm_action = this.status
          this.requestPrompt = false
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message })
        })
    },
    clearField () {
      this.old_password = ''
      this.new_password = ''
      this.confirm_new_password = ''
    },
    changePassword () {
      //start of method
      const data = {
        current_password: this.old_password,
        new_password: this.new_password
      }

      if (
        this.validateForm &&
        this.new_password === this.confirm_new_password
      ) {
        this.$vs.loading()
        this.$http
          .put('/user/changePassword', data)
          .then((res) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Change Password',
              text: res.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
          })
          .catch((err) => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Change Password',
              text: err.res.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
      } else {
        this.$vs.notify({
          title: 'Change Password',
          text: 'فیلد ها را بررسی کنید و سپس دوباره اقدام به تعویض رمز نمایید',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
      //end of method
    }
  }
}
</script>
