<template>
  <div>
    <div class="block w-full text-right mr-2 mb-8">
         <!-- <router-link to="/dashboard" class=" text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به داشبورد</router-link> -->
       </div>

  <vs-tabs v-model="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none  " id="profile-tabs" :key="isSmallerScreen" >

    <vs-tab icon-pack="feather" icon="icon-user" :label=" 'اطلاعات کاربری'" class="">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0 bg-h-primary-light">
        <user-settings-general />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-phone" :label=" 'احراز شماره تماس'" class="">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0 bg-h-primary-light">
        <user-identity-setting />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-mail" :label=" 'احراز ایمیل'" class="">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0 bg-h-primary-light">
        <user-email-setting />
      </div>
    </vs-tab>
<!--    <vs-tab icon-pack="feather" icon="icon-pocket" :label=" ' تبدیل به حقوقی'">-->
<!--      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">-->
<!--        <user-settings-info />-->
<!--      </div>-->
<!--    </vs-tab>-->
<!--    <vs-tab icon-pack="feather" icon="icon-shopping-bag" :label=" '  همکاری در فروش '">-->
<!--      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">-->
<!--        <SalesCooperation />-->
<!--      </div>-->
<!--    </vs-tab>-->

    <vs-tab icon-pack="feather" icon="icon-book" :label=" 'تنظیمات امنیتی'" class="text-h-warning">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-change-password />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-pocket" :label="'ساخت کلید API'">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-api-key />
      </div>
    </vs-tab>
  </vs-tabs>
</div>
</template>

<script>
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSettingsChangePassword from './UserSettingsChangePassword.vue'
import UserSettingsInfo from './UserSettingsInfo.vue'
// import SalesCooperation from './SalesCooperation'
import UserSettingsApiKey from './UserSettingsApiKey.vue'
import UserEmailSetting from './UserEmailSetting.vue'
import UserIdentitySetting from './UserIdentitySetting.vue'

export default {
  components: {
    // SalesCooperation,
    UserSettingsGeneral,
    UserSettingsChangePassword,
    UserEmailSetting,
    UserIdentitySetting,
    UserSettingsInfo,
    UserSettingsApiKey
  },
  data () {
    return {
      activeTab: this.$route.query.activeTab || 0
    }
  },
  methods: {
    // ActiceTabSalesCooperation () {
    //   this.$route.query.salescooperation.length > 0 ? this.activeTab = 2 : this.activeTab = 1
    // }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  created () {
    // this.ActiceTabSalesCooperation()
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
