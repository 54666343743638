<template>
  <vx-card >
    <div class="mt-8 flex">
       <vs-input class="w-full mt-8 mx-1" label="نام شرکت : " v-model="legalInfo.company_name" />
       <vs-select class="w-full mt-8 mx-1" label="استان : " v-model="selectedState"  width="120px" >
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in province" />
      </vs-select> 
      <vs-select class="w-full mt-8 mx-1" label="شهر : " v-model="selectedCity"  width="120px" >
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in cities" />
      </vs-select>  

   </div>

    <!-- Languages -->
     <div class="mt-4 flex">
       <vs-input class="w-full  mx-1 mt-8" label-placeholder="کد پستی : " v-model="legalInfo.postal_code" />
       <vs-input class="w-full mx-1 mt-8" label-placeholder="تلفن  : "  v-model="legalInfo.phone" />

    </div>
   <div class="mt-8">
       <vs-textarea class="w-full  mx-1  mt-8" label=" آدرس : " v-model="legalInfo.address" />
   </div>
    <div class="mt-4 flex">
       <vs-input class="w-full mx-1 mt-8" label-placeholder="شناسه ملی : " v-model="legalInfo.national_id" />
              <vs-input class="w-full  mx-1 mt-8" label-placeholder="شماره ثبت  : " v-model="legalInfo.company_registration_number" />

       <vs-input class="w-full mx-1 mt-8" label-placeholder="کد اقتصادی : "  v-model="legalInfo.economic_code" />
       <!-- <vs-input class="w-full mx-1 mt-8" label-placeholder="کد مالیاتی : "  v-model="legalInfo.tax_id" /> -->
    </div>

    <!-- Save & Reset Button -->
    <div class="flex mt-8 flex-wrap items-center justify-end">
      <vs-button  @click="createUserLegal" class="ml-auto mt-2 rounded">ذخیره تغییرات</vs-button>
       <vs-button  type="border" color="#24c1a0" @click="updateUserLegal" class="ml-3 mt-2 rounded"> ویرایش</vs-button>

    </div>
  </vx-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Farsi from 'flatpickr/dist/l10n/fa.js'
export default {
  components: {
    flatPickr
    
  },
  data () {
    return {
      selectedCity : '',
      cities : [],
      selectedState : '',
      province : [],
      legalInfo : {
        company_name : '',
        province : '',
        city : '',
        address : '',
        postal_code: '',
        company_registration_number: '',
        phone : '',
        national_id :'',
        economic_code: '',
        tax_id : ''

      },
      bio: this.$store.state.AppActiveUser.about,
      dob: null,
      mobile: '',
      website: '',
      config: {
        locale: Farsi // locale for this instance only          
      }

    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  watch : {
    selectedState (val) {
      this.getAllCity(val)
    }
  },
  methods : {
    createUserLegal () {
      this.legalInfo.province_id = this.selectedState
      this.legalInfo.city_id = this.selectedCity
      this.$http.post('/user/legal-personality', this.legalInfo).then((result) => {
        this.$vs.notify({text: result.data.message})
      }).catch((err) => {
        this.$vs.notify({text : err.response.data.message})
      })
    },
    getUserLegal () {
      this.$http.get('/user/legal-personality').then((result) => {
        if (result.data.params) {
          this.legalInfo = result.data.params
          this.selectedState = this.legalInfo.province_id
          this.selectedCity = this.legalInfo.city_id
        }
      }).catch((err) => {
        this.$vs.notify({text : err.response.data.message})
      })
    },
    updateUserLegal () {
      this.legalInfo.province_id = this.selectedState
      this.legalInfo.city_id = this.selectedCity
      this.$http.put('/user/legal-personality', this.legalInfo).then((result) => {
        this.$vs.notify({text: result.data.message, color: '#24c1a0'})
      }).catch((err) => {
        this.$vs.notify({text : err.response.data.message})
      })
    },
    getAllState () {
      this.$http.get('/provinces').then((result) => {
        this.province = result.data.params
      }).catch((err) => {
        this.$vs.notify({text : err.response.data.message})
      })
    },
    getAllCity (id) {
      this.$http.get(`/cities/${id}`).then((result) => {
        this.cities = result.data.params
      }).catch(() => {
        
      })
    }
  },
  created () {
    this.getUserLegal()
    this.getAllState()
  }
}
</script>
