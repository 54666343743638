<template>
  <vx-card>
    <div>
      <vs-button class="secondary-color پغ-۲" @click="createPopUp=!createPopUp"  icon="add"
        >ایجاد کلید API
      </vs-button>
    </div>
    <!-- API KEYS TABLE -->
    <b-table
     v-if="apiList.length > 0"
      :stacked="windowWidth < 800"
      :striped="windowWidth > 800"
      head-variant="dark"
      class="border text-center table-off mt-4 text-white"
      responsive="sm"
      :items="apiList"
      :fields="field"
    >
    <template #cell(btn)="data">
       <div class="flex justify-center text-white">
            <vs-button
                @click="deleteApiKey(data.item.id)"
                color="danger"
                size="small"
                radius
                type="gradient"
                icon="delete"
              >
              </vs-button>
              <vs-button
                class="mx-1"
                @click="copy(data.item.api_key)"
                color="success"
                size="small"
                radius
                type="gradient"
                icon="content_copy"
              >
              </vs-button>
       </div>
    </template>
    </b-table>
     <div class="flex flex-column justify-center items-center w-full" v-else>
        <img width="150" src="@/assets/images/elements/open-box.svg" alt="haio-logo">
        <h5>موردی جهت نمایش وجود ندارد</h5>
      </div>
    <!-- CREATE API KEY POPUP -->
    <div>
      <vs-popup
        class="holamundo"
        title="عنوان "
        :active.sync="createPopUp"
      >
      <div></div>
      <div class="flex ">
        <label class="w-25 mt-3 text-white" for="keyTitle">عنوان کلید : </label>
        <b-form-input class="my-2 mx-5 text-white" v-model="keyTitle"  id="keyTitle"></b-form-input>
      </div>
      <vs-button class="mt-3 float-right mx-4" @click="createApiKey">ایجاد کلید</vs-button>
      </vs-popup>
    </div>

    <!-- DELETE API KEY POPUP -->
  </vx-card>
</template>

<script>
export default {
  data () {
    return {
      createPopUp: false,
      keyTitle : '',
      apiList: [],
      field : [
        {key: 'title', label: 'دامنه'},
        {key : 'status_text', label: 'فعال'},
        {key:'btn', label: 'عملیات', tdClass: 'align-middle'}
      ]
   
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    createApiKey () {
      const data = {
        api_key_title : this.keyTitle
      }
      this.$http
        .post('/api/key/add', data)
        .then(res => {
          this.$vs.notify({
            text:res.data.message,
            color:'success'
          })
          this.createPopUp = false
          this.listApiKey()
        })
        .catch(err => {
          this.$vs.notify({
            text:err.response.data.message,
            color: 'danger'
          })
        })
    },
    listApiKey () {
      this.$http
        .get('/api/key/list')
        .then(res => {
          this.apiList = res.data.params.data
        })
        .catch()
    },
    deleteApiKey (id) {
      this.$http
        .get(`/api/key/delete/${id}`)
        .then(res => {
          this.$vs.notify({
            text:res.data.message,
            color: 'success'
          })
          this.listApiKey()
        })
        .catch(err => {
          this.$vs.notify({
            text:err.response.data.message,
            color: 'danger'
          })
        })
    },
    copy (txt) {
      this.$copyText(txt).then(() => {
        this.$vs.notify({
          color:'success',
          title: 'کلید API   کپی شد '
        })
      })
    }
  },
  created () {
    this.listApiKey()
  }
}
</script>
<style>
table {color: #fff !important;}
[dir] .table-striped tbody tr:nth-of-type(odd) {
    background-color: #20223c;
}
[dir] .table-striped tbody tr {
    background-color: #262c49;
    color:#fff  
}
thead {
    background-color: #dbdbdb;
    color: #090c2b;
}
</style>