<template>
  <vx-card no-shadow class="bg-h-primary-light rounded-lg">
    <div>
      <vs-alert :active="this.AlertShow && (userInfo.identity == 1)" color="#0092e1" icon="new_releases" >
        <span class="text-white">
          احراز هویت شما انجام شده است.
        </span>
      </vs-alert>

      <vs-alert :active="(this.AlertShow && (userInfo.identity == 3))" icon="new_releases" >
        <span class="text-warning">
          کد تایید ارسال شده به شماره همراه {{userInfo.mobile}} را وارد نمایید. (
          <vs-button @click="getVerifyEmail" size="small" color="warning" style="padding: 5px">دریافت مجدد کدتایید</vs-button>
          )
        </span>
      </vs-alert>
      <vs-alert :active="(this.AlertShow && (userInfo.identity == 0 || userInfo.identity == 2))" icon="new_releases" >
        <span class="text-warning">
          جهت استفاده از خدمات لازم است احراز هویت خود را انجام دهید.
        </span>
      </vs-alert>
      <vx-card>
        <!-- Bio -->
        <div class="mt-4 flex">
          <vs-input
            v-if="this.AlertShow && !this.HideForm"
            :disabled="(userInfo.identity == 1)"
            class="mx-1 mt-8 col-md-6"
            type="text"
            style="direction:ltr;"
            label-placeholder="شماره همراه (جهت ورود به کنسول):"
            v-model="userInfo.mobile"
          />
        </div>
        <div class="mt-4 flex">
          <vs-input
            v-if="this.AlertShow && !this.HideForm"
            :disabled="(userInfo.identity == 1)"
            class="mx-1 mt-8 col-md-6"
            type="text"
            style="direction:ltr;"
            label-placeholder="کدملی مرتبط با شماره همراه:"
            v-model="userInfo.national_id"
          />
        </div>
        <div class="mt-4 flex">
          <vs-input
            v-if="this.AlertShow && (userInfo.identity == 3) && this.HideForm"
            class="mx-1 mt-8 col-md-6"
            type="text"
            style="direction:ltr;"
            label-placeholder="کد تایید پیامک شده:"
            v-model="SmsCode"
          />
        </div>
        <!-- Save & Reset Button -->
        <div class="flex mt-8 flex-wrap items-center justify-end">

          <vs-button v-if="this.AlertShow && (userInfo.identity != 1) && !this.HideForm"
            type="border"
            color="#24c1a0"
            @click="updateIdentity"
            class="ml-3 mt-2 rounded"
          >
            ذخیره</vs-button
          >

          <vs-button v-if="this.AlertShow && (userInfo.identity == 3) && this.HideForm"
                     type="border"
                     color="#24c1a0"
                     @click="confirmIdentity"
                     class="ml-3 mt-2 rounded "
          >
            تایید شماره همراه</vs-button
          >

        </div>
      </vx-card>
    </div>
    <div class="flex flex-wrap items-center justify-end">
    </div>
  </vx-card>
</template>

<script>

export default {

  data () {
    return {
      userInfo: [],
      AlertShow: false,
      HideForm: false,
      SmsCode: ''
    }
  },
  watch : {
  },
  computed: {
  },
  methods : {
    updateIdentity () {
      this.$vs.loading()
      this.$http.put('/user/identity', {mobile: this.userInfo.mobile, national_id: this.userInfo.national_id}).then((result) => {
        this.$vs.loading.close()
        this.$vs.notify({text: result.data.message, color: '#24c1a0'})
        this.getUserInfo()
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({
          color: 'danger',
          text: err.response.data.message})
      })
    },
    confirmIdentity () {
      this.$vs.loading()
      this.$http.post('/user/verify/identity', {token: this.SmsCode}).then((result) => {
        this.$vs.loading.close()
        this.$vs.notify({text: result.data.message, color: '#24c1a0'})
        this.getUserInfo()
        this.HideForm = false
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({
          color: 'danger',
          text: err.response.data.message})
      })
    },
    getUserInfo () {
      this.$http.get('/user/info', this.userInfo).then((result) => {
        this.userInfo = result.data.params
        if (this.userInfo.identity === 3) {
          this.HideForm = true
        }
        this.AlertShow = true
      }).catch((err) => {
        console.log(err)
      })
    },
    getVerifyEmail () {
      this.$vs.loading()
      this.$http.get('/user/verify/identity/resend').then((res) => {
        if (res.data.code === 200) {
          this.$vs.loading.close()
          this.$vs.notify({text: 'کد تایید مجدد برای شما پیامک شد ', color: '#24c1a0'})
        }
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({color:'danger', text: err.response.data.message})
      })
    }
  },
  created () {
    this.getUserInfo()
  },
  mounted () {
  }
}
</script>
<style>
.input-span-placeholder {
  direction: ltr !important;
}
</style>
