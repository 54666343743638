<template>
  <vx-card no-shadow class="bg-h-primary-light rounded-lg">
    <div>
      <vs-alert :active="this.AlertShow && userInfo.email_verify" color="#0092e1" icon="new_releases" >
        <span class="text-white">
          آدرس ایمیل شما قبلاً تایید شده است.
        </span>
      </vs-alert>

      <vs-alert :active="(this.AlertShow && userInfo.email && !userInfo.email_verify)" icon="new_releases" >
        <span class="text-warning">
          لینک تایید آدرس ایمیل برای شما ارسال شده است. (
          <vs-button @click="getVerifyEmail" size="small" color="warning" style="padding: 5px">دریافت مجدد ایمیل</vs-button>
          )
        </span>
      </vs-alert>
      <vx-card>
        <!-- Bio -->
        <div class="mt-4 flex">
          <vs-input
            :disabled="userInfo.email_verify"
            class="mx-1 mt-8 col-md-6"
            type="email"
            label-placeholder="آدرس ایمیل:"
            v-model="userAddress"
          />
        </div>
        <!-- Save & Reset Button -->
        <div class="flex mt-8 flex-wrap items-center justify-end">

          <vs-button v-if="this.AlertShow && !userInfo.email_verify"
            type="border"
            color="#24c1a0"
            @click="updateEmailInfo"
            class="ml-3 mt-2 rounded"
          >
            ذخیره</vs-button
          >
        </div>
      </vx-card>
    </div>
    <div class="flex flex-wrap items-center justify-end">
    </div>
  </vx-card>
</template>

<script>

export default {

  data () {
    return {
      userInfo: [],
      userAddress: '',
      AlertShow: false
    }
  },
  watch : {
  },
  computed: {
  },
  methods : {
    updateEmailInfo () {
      this.$vs.loading()
      this.$http.put('/user/update/email', {email: this.userAddress}).then((result) => {
        this.$vs.loading.close()
        this.$vs.notify({text: result.data.message, color: '#24c1a0'})
        this.getUserInfo()
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({
          color: 'danger',
          text: err.response.data.message})
      })
    },
    getUserInfo () {
      this.$http.get('/user/info').then((result) => {
        this.userInfo = result.data.params
        this.AlertShow = true
        this.userAddress = this.userInfo.email
      }).catch((err) => {
        console.log(err)
      })
    },
    getVerifyEmail () {
      this.$vs.loading()
      this.$http.post('/user/getVerifyEmail').then((res) => {
        if (res.data.code === 200) {
          this.$vs.loading.close()
          this.$vs.notify({text: 'ایمیل تایید مجدد برای شما ارسال شد ', color: '#24c1a0'})
        }
      }).catch((err) => {
        this.$vs.loading.close()
        this.$vs.notify({color:'danger', text: err.response.data.message})
      })
    }
  },
  created () {
    this.getUserInfo()
  },
  mounted () {
  }
}
</script>
