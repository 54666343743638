<template>
  <vx-card no-shadow class="bg-h-primary-light rounded-lg">
    <div>
      <vx-card>
        <!-- Bio -->
       <div style="flex-direction: column" class="mt-8 flex lg:flex-row md:flex-row">
          <template v-if="userInfo.first_name && userInfo.last_name">
            <vs-input
              class="w-full mt-8 mx-1"
              label="نام   : * "
              v-model="userInfo.first_name"
            />
            <vs-input
              class="w-full mt-8 mx-1"
              label="نام خانوادگی  : * "
              v-model="userInfo.last_name"
            />
          </template>
          <template v-else>
            <vs-input
              class="w-full mt-8 mx-1"
              label="نام   : * "
              v-model="userInfo.first_name"
            />
            <vs-input
              class="w-full mt-8 mx-1"
              label="نام خانوادگی  : * "
              v-model="userInfo.last_name"
            />
          </template>
          <!-- <vs-input class="w-full mx-1 mt-8" label-placeholder="استان :" v-model="website" /> -->
          <vs-select class="w-full mt-8 mx-1" label="استان : * " v-model="selectedProvince"  width="120px" >
              <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in province" />
        </vs-select>
          <vs-select class="w-full mt-8 mx-1" label="شهر : * " v-model="selectedCity"  width="120px" >
              <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in cities" />
        </vs-select>
        </div>

        <!-- Languages -->
        <div class="mt-4 flex">
          <vs-input
            class="w-full mx-1 mt-8"
            label-placeholder="کد پستی : "
            v-model="userInfo.postal_code"
          />
        </div>
        <div class="mt-8">
          <vs-textarea
            class="w-full mx-1 mt-8"
            label=" آدرس : "
            v-model="userInfo.address"
          />
        </div>
        <div class="mt-4 flex">
          <vs-input
            class="mx-1 mt-8"
            label-placeholder="تلفن  : "
            v-model="userInfo.phone"
          />
        </div>

        <!-- Save & Reset Button -->
        <div class="flex mt-8 flex-wrap items-center justify-end">

          <vs-button
            type="border"
            color="#24c1a0"
            @click="updateUserInfo"
            class="ml-3 mt-2 rounded"
          >
            ویرایش</vs-button
          >
        </div>
      </vx-card>
    </div>
    <div class="flex flex-wrap items-center justify-end">
      <!-- <vs-button class="ml-auto mt-2">ذخیره تغییرات</vs-button> -->
      <!-- <vs-button class="ml-4 mt-2" type="border" color="warning">بازن</vs-button> -->
    </div>
  </vx-card>
</template>

<script>

export default {

  data () {
    return {
      province: [],
      cities : [],
      selectedCity: '',
      selectedProvince: '',
      userInfo : {
        name : '',
        first_name: '',
        last_name: '',
        state : '',
        city : '',
        address : '',
        postal_code: '',
        phone : '',
        national_id :''

      },
      username: this.$store.state.AppActiveUser.name,
      mobile: this.$store.state.AppActiveUser.mobile,
      email: this.$store.state.AppActiveUser.email
    }
  },
  watch : {
    selectedProvince (val) {
      this.getAllCity(val)
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }

  },
  methods : {
    updateUserInfo () {
      this.userInfo.province_id = this.selectedProvince
      this.userInfo.city_id = this.selectedCity
      this.$http.put('/user/update', this.userInfo).then((result) => {
        this.$vs.notify({text: result.data.message, color: '#24c1a0'})
        this.getUserInfo()
      }).catch((err) => {
        this.$vs.notify({
          color: 'danger',
          text: err.response.data.message})
      })
    },
    getUserInfo () {
      this.$http.get('/user/info', this.userInfo).then((result) => {
        const data = {
          name : result.data.params.name,
          first_name: result.data.params.first_name,
          last_name: result.data.params.last_name,
          state : result.data.params.state,
          city : result.data.params.city,
          address : result.data.params.address,
          postal_code: result.data.params.postal_code,
          phone : result.data.params.phone,
          national_id : result.data.params.national_id

        }
        this.selectedProvince = result.data.params.province_id
        this.selectedCity = result.data.params.city_id
        this.userInfo = data
      }).catch((err) => {
        console.log(err)
      })
    },
    getAllState () {
      this.$http.get('/provinces').then((result) => {
        this.province = result.data.params
      }).catch((err) => {
        console.log(err.response.data.message)
      })
    },
    getAllCity (id) {
      this.$http.get(`/cities/${id}`).then((result) => {
        this.cities = result.data.params
      }).catch(() => {

      })
    }
  },
  created () {
    this.getUserInfo()
    this.getAllState()
  },
  mounted () {

  }
}
</script>
